import {
  AccessGroupsByObjectId,
  AccessUsersByObjectId,
  ClassificationGroupedItem,
  ClassificationObject,
  ClassificationsGroupedParams,
  ClassificationsPrint,
  ClassificationTopWidget,
  ClassifiedObjectsParams,
  FileListResponseType,
  ObjectsByDataSourceParams,
  ObjectSummary
} from './classificationsSlice'
import { AnalyticsItem } from './classificationsAnalytics'
import { ENTITY_DETAILS_LIMIT } from './constants'
import {
  DATA_SOURCE_TYPES,
  FILTER_LAST_MODIFIED_TIME_KEY,
  LABELS_KEY,
  LAST_MODIFIED_TIME,
  LIMIT_DEFAULT,
  MAIL_DATASOURCES,
  mapDataSourceApiTypesToStandard,
  POLICY_TYPES,
  SENSITIVE_LABEL
} from '../../constants'
import { IGetAttributesParams, IGetAttributesTableListParams } from '../attributes/attributesSlice'
import { getAfterCursor, parameterizeArrayofObjects } from '../../utils/graphqlUtil'
import { FilterParams, IDocument } from '../../interfaces'
import { DocumentClassCards } from '../../services/api/apiTypes'
import { mapStringToTimeStamp, mapWithinStringToTimeStamp } from '../../utils/timeUtil'
import { GetAccessControlUserByObjectIdFilterParams } from '../accessControl/types'
import { gql } from 'graphql-request'

export const queryClassificationsGrouped = (params: ClassificationsGroupedParams): string => {
  const labelsInitFilter = params.filters?.filter?.find((filter) => filter.key === LABELS_KEY)
  // remove labels filter from filters array
  let documentLabelIds: string[] = []
  if (params.filters?.filter && labelsInitFilter) {
    documentLabelIds = labelsInitFilter.values
    params.filters.filter = params.filters.filter.filter((filter) => filter.key !== LABELS_KEY)
  }

  const filterString =
    params.filters && params.filters.filter ? parameterizeArrayofObjects(params.filters.filter) : ''
  const booleanFilterString =
    params.filters && params.filters.booleanFilter
      ? parameterizeArrayofObjects(params.filters.booleanFilter)
      : ''
  let filter = ''

  if (filterString) {
    filter += `filter: ${filterString}`
  }
  if (booleanFilterString) {
    filter += `, booleanFilter: ${booleanFilterString} `
  }

  const documentLabelIdsFilter = documentLabelIds.length
    ? `, documentLabelIds: ${JSON.stringify(documentLabelIds)}`
    : ''

  const objectFilters =
    filter || documentLabelIdsFilter ? `(${filter}${documentLabelIdsFilter})` : ''

  return gql`
    {
      objectGroupByClassificationType ${objectFilters} {
        classification
        subclass
        objectCount
        riskyCount
      }
    }
  `
}

export const mapQueryClassificationsGrouped = (raw: any): ClassificationGroupedItem[] => {
  try {
    return raw.objectGroupByClassificationType.map((cl) => ({
      name: cl.classification,
      count: cl.objectCount || 0,
      subClass: cl.subclass,
      riskyCount: cl.riskyCount || 0
    }))
  } catch (e) {
    console.error(e)
    return []
  }
}

export const mapQueryAwsClassifiactionsList = (
  queryResponse: any,
  blobId?: string
): FileListResponseType => {
  try {
    if (blobId) {
      const list =
        queryResponse && queryResponse?.columns && queryResponse?.columns?.edges
          ? queryResponse?.columns?.edges[0]?.node?.blobCell?.edges?.map((item) => ({
              rowId: item?.node?.rowId,
              attributes: item?.node?.attributes
            }))
          : []
      return { list, total: queryResponse.columns.count || 0 }
    } else {
      const list =
        queryResponse && queryResponse.objects && queryResponse.objects.edges
          ? queryResponse.objects.edges.map(({ node }) => ({
              id: node.id,
              name: node.name,
              classification: node.classification,
              fileType: node.type,
              lastModifiedTime: node.lastModifiedTime,
              owner: node.owner,
              link: node.objectLink,
              metadataId: node.metadata?.incidentNumber || '',
              retainUntilTimestamp: node.retainUntilTimestamp,
              entitiesCount: node.entity.count,
              entities: node.entity?.edges.map(({ node: item }) => ({
                id: item.id,
                names: item.name
              })),
              attributesCount: node.attribute.count,
              attributes: node.attribute?.edges.map(({ node: item }) => ({
                internalName: item.internalName,
                name: item.name
              })),
              alertsCount: node.alert?.count,
              alerts: node.alert?.edges.map(({ node: item }) => ({
                id: item.id,
                name: item.name,
                severity: item.severity || ''
              })),
              buckets: node.bucket.edges.map(({ node: item }) => ({
                name: item.name
              })),
              drive: []
            }))
          : []

      return { list, total: queryResponse.objectCount.count || 0 }
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryAccessUserByObjectId = (params: GetAccessControlUserByObjectIdFilterParams) => {
  const { id: objectId, pageSize, after } = params

  return gql`
    {
      objects(first: 1, id: "${objectId}") {
        edges {
          node {
            userAccess(first: ${pageSize}, after: "${after}") {
              totalCount: count
              edges {
                cursor
                node {
                  name
                  id
                  departmentName
                  employeeType
                  dataPrivilegeLevel
                  memberOfGroups {
                    edges {
                      node {
                        name
                        id
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `
}

export const mapQueryAccessUserByObjectId = (queryResponse: any): AccessUsersByObjectId => {
  const { userAccess } = queryResponse.objects.edges[0].node

  try {
    return {
      totalCount: userAccess.totalCount,
      users: userAccess.edges.map(({ node }) => ({
        name: node.name,
        id: node.id,
        departmentName: node.departmentName,
        employeeType: node.employeeType,
        dataPrivilegeLevel: node.dataPrivilegeLevel,
        memberOfGroups: node?.memberOfGroups?.edges?.map(({ node }) => ({
          id: node.id,
          name: node.name
        }))
      }))
    }
  } catch (error) {
    console.error(error)
    return { totalCount: 0, users: [] }
  }
}

export const queryAccessGroupByObjectId = (objectId: string, pageSize: number, after = '') => gql`
{
    objects(first: 1, id: "${objectId}") {
      edges {
        node {
          groupAccess(first: ${pageSize}, after: "${after}") {
            totalCount: count
            edges {
              cursor
              node {
                name
                id
                memberUsers {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`

export const mapQueryAccessGroupByObjectId = (queryResponse: any): AccessGroupsByObjectId => {
  const { groupAccess } = queryResponse.objects.edges[0].node

  return {
    totalCount: groupAccess.totalCount,
    groups: groupAccess.edges.map(({ node }) => ({
      id: node.id,
      name: node.name,
      memberUsers: node.memberUsers
    }))
  }
}

export const queryObjectById = (id: string): string => gql`
  {
    objects(first: 1, id: "${id}") {
      edges {
        node {
          sharedOn
          objectLink
          accessType
          lastModifiedTime
          retainUntilTimestamp
          owner
          messageId
          highSensitivityAttributes: attribute(sensitivity: ${SENSITIVE_LABEL.HIGH}) {
            count
          }
          mediumSensitivityAttributes: attribute(sensitivity: ${SENSITIVE_LABEL.MEDIUM}) {
            count
          }
          lowSensitivityAttributes: attribute(sensitivity: ${SENSITIVE_LABEL.LOW}) {
            count
          }
          userAccess {
            count
          }
          groupAccess {
            count
          }
          entity {
            count
          }
          datasource {
            edges {
              node {
                idp {
                edges {
                  node {
                    id
                  }
                }
              }
                status: state
              }
            }
          }
          alert(first: 2) {
            count
            edges {
              node {
                id
                severity
                policy {
                  edges {
                    node {
                      policyType {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
                name
              }
            }
          }
          metadata {
            ... on ServiceNowMetadata {
              incidentNumber
            }
          }
          drive {
            edges {
              node {
                driveName
              }
            }
          }
          bucket(first: 1) {
            edges {
              node {
                name
                displayName
              }
            }
          }
        }
      }
    }
  }

`

export const mapQueryObjectById = (queryResponse: any): ObjectSummary => {
  try {
    const {
      sharedOn: sharedWhen = '',
      objectLink: link = '',
      lastModifiedTime = '',
      retainUntilTimestamp,
      owner,
      messageId = '',
      highSensitivityAttributes: { count: highSensitivityAttributes } = { count: 0 },
      mediumSensitivityAttributes: { count: mediumSensitivityAttributes } = { count: 0 },
      lowSensitivityAttributes: { count: lowSensitivityAttributes } = { count: 0 },
      userAccess,
      groupAccess,
      accessType = '',
      metadata,
      drive,
      bucket,
      alert,
      entity,
      datasource
    } = queryResponse?.objects?.edges[0]?.node || {}

    return {
      lastModifiedTime,
      sharedWhen,
      link,
      accessType,
      incidentNumber: metadata?.incidentNumber || '',
      messageId,
      driveName: drive?.edges[0]?.node?.driveName || '',
      bucketName: bucket?.edges[0]?.node?.displayName || bucket?.edges[0]?.node?.name || '',
      idpId: datasource?.edges[0]?.node?.idp?.edges[0]?.node?.id,
      dataSourceStatus: datasource?.edges[0]?.node?.status,
      alerts: {
        list: alert?.edges?.map?.(({ node }) => ({
          name: node?.name,
          id: node?.id,
          severity: node?.severity
        })),
        count: alert?.count || 0,
        isViolatingRetentionPolicy: alert?.edges?.some?.(({ node }) => {
          const { policy } = node
          const policyType = policy?.edges?.[0]?.node?.policyType?.edges?.[0]?.node?.name
          return policyType === POLICY_TYPES.RETENTION
        })
      },
      retainUntilTimestamp,
      owner,
      highSensitivityAttributes,
      mediumSensitivityAttributes,
      lowSensitivityAttributes,
      userAccess,
      groupAccess,
      entityCount: entity?.count
    }
  } catch (e) {
    return { lastModifiedTime: '', sharedWhen: '', link: '' }
  }
}

export const queryTopClassification = (params: IGetAttributesParams): string => {
  const { userEntityId, datasourceId } = params
  let paramStr = ''
  if (userEntityId) {
    paramStr += `entityId:"${userEntityId}"`
  }
  if (datasourceId) {
    paramStr += `datasourceIds: ["${datasourceId}"]`
  }
  if (paramStr) {
    paramStr = `(${paramStr})`
  }
  return gql`
  {
    attributeInstanceGroupedbyDocumentClass${paramStr} {
      documentClass
      attributeInstanceCount
    }
  }`
}

export const queryObjectsByDataSource = (params?: ObjectsByDataSourceParams): string => {
  const { filters } = params || {}

  let filter = ''
  if (filters && !!Object.values(filters).length) {
    const filtersStr = Object.entries(filters).map(([key, values]) => {
      return `{key: ${key}, values: ${JSON.stringify(values)}}`
    })
    filter += `filter: [${filtersStr}],`
  }

  return gql`
    {
      datasources {
        count
        edges {
          node {
            id
            name
            type
            objects: objects(first: 1, ${filter}) {
              count
            }
            structuredObjects: tables(first: 1) {
              count
            }
            riskyObjects: objects(first: 1, isRisky: true, ${filter}) {
              count
            }
          }
        }
      }
    }
  `
}

export const mapQueryObjectsByDataSource = (queryResponse: any): IDocument[] => {
  try {
    const list =
      queryResponse && queryResponse.datasources && queryResponse.datasources.edges
        ? queryResponse.datasources.edges.map(({ node }) => ({
            datasourceId: node.id,
            datasourceName: node.name,
            datasourceType: node.type,
            objectsCount: node.objects.count || 0,
            structuredObjectsCount: node.structuredObjects.count || 0,
            riskyObjectsCount: node.riskyObjects.count || 0
          }))
        : []
    return list
  } catch (e) {
    return []
  }
}

export const queryDocumentList = (
  params: IGetAttributesParams &
    IGetAttributesTableListParams & {
      filters?: FilterParams
      retainUntil?: string
      lastModifiedTime?: string
      retainUntilTime?: string
      deleteAfterTimestamp?: string
    }
): string => {
  const { datasourceId, datasourceType, page, filters: _filters, lastModifiedTime } = params

  const filters = JSON.parse(JSON.stringify(_filters))

  // Labels
  const labelFilter = filters?.documentLabelIds

  const dateFilterKey = FILTER_LAST_MODIFIED_TIME_KEY
  const dateFilterValue = lastModifiedTime

  const { startTimestamp, endTimestamp } = [
    LAST_MODIFIED_TIME.withinThreeMonths,
    LAST_MODIFIED_TIME.withinSixMonths,
    LAST_MODIFIED_TIME.withinOneYear
  ].includes(lastModifiedTime as LAST_MODIFIED_TIME)
    ? mapWithinStringToTimeStamp(lastModifiedTime)
    : mapStringToTimeStamp(lastModifiedTime)
  const dateFilter = dateFilterValue
    ? `dateFilter: {key: ${dateFilterKey}, start: ${startTimestamp}, end:${endTimestamp} }`
    : ''
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = `${dateFilter}`
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''
  if (datasourceId) {
    paramStr += `, datasourceIds:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (labelFilter) {
    paramStr += `, documentLabelIds: ${JSON.stringify(labelFilter)}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT},${paramStr},after:"${cursor}", category:FILE)`
  }

  const isDrives =
    datasourceType === DATA_SOURCE_TYPES.gdrive ||
    datasourceType === DATA_SOURCE_TYPES.gdrivePersonal ||
    datasourceType === DATA_SOURCE_TYPES.oneDrive ||
    datasourceType === DATA_SOURCE_TYPES.box ||
    datasourceType === DATA_SOURCE_TYPES.dropbox
  const FRAGMENT_DRIVES = `
    drive {
      edges {
        node { id, driveName }
      }
    }
  `

  const isBuckets =
    datasourceType === DATA_SOURCE_TYPES.aws || datasourceType === DATA_SOURCE_TYPES.azureBlob
  const FRAGMENT_BUCKETS = `
    bucket{
      edges{
        node{
          name
          displayName
        }
      }
    }
  `

  const isTickets = datasourceType === DATA_SOURCE_TYPES.jira
  const FRAGMENT_TICKETS = `
    ticket {
      edges {
        node {
          id
          lastModifiedTime
          ticketName
          ticketLink
          ticketStoreProject {
            edges {
              node {
                projectName
              }
            }
          }
          reporter {
            name
          }
          assignee {
            name
          }
        }
      }
    }
  `

  const isMails = MAIL_DATASOURCES.includes(datasourceType as DATA_SOURCE_TYPES)
  const FRAGMENT_MAILS = `
    mail {
      edges {
        node {
          id
          isDeleted
          messageId
        }
      }
    }
  `

  const isIncidents = datasourceType === DATA_SOURCE_TYPES.serviceNow
  const FRAGMENT_INCIDENTS = `
    metadata {
      ... on ServiceNowMetadata {
        incidentNumber
      }
    }
  `

  return gql`
  {
    objects${paramStr}{
      edges {
        node {
          id
          name,
          classification,
          type,
          markedForRescan,
          lastModifiedTime,
          owner,
          objectLink,
          skipReason,
          retainUntilTimestamp,
          alert(first:5, filter: [{key: STATUS, values: ["Active"]}]){
            count
            edges{
              node{
                id
                name
                severity
              }
            }
          }
          labels {
            edges {
              node {
                id
                name
                labelSetId
                labelSetName
                mappedLabel {
                  edges {
                    node {
                      id
                      name
                      labelSetId
                      labelSetName
                    }
                  }
                }
              }
            }
          }
          attribute {
            count
            edges {
              node {
                internalName
                name
              }
            }
          }
          entity(first: ${ENTITY_DETAILS_LIMIT}) {
            count
            edges {
              node {
                id
                name
              }
            }
          }
          ${isDrives ? FRAGMENT_DRIVES : ''}
          ${isBuckets ? FRAGMENT_BUCKETS : ''}
          ${isTickets ? FRAGMENT_TICKETS : ''}
          ${isMails ? FRAGMENT_MAILS : ''}
          ${isIncidents ? FRAGMENT_INCIDENTS : ''}
        }
      }
    }
  }`
}

export const mapQueryDocumentList = (queryResponse: any): FileListResponseType => {
  try {
    const list =
      queryResponse?.objects?.edges.map(({ node }) => ({
        id: node.id,
        name: node.name,
        classification: node.classification,
        skipReason: node.skipReason || '',
        fileType: node.type,
        markedForRescan: node.markedForRescan,
        lastModifiedTime: node.lastModifiedTime,
        retainUntilTimestamp: node.retainUntilTimestamp,
        owner: node.owner,
        isDeleted: node?.mail?.edges?.some(({ node }) => node.isDeleted) || false,
        messageId: node?.mail?.edges[0]?.node?.messageId || '',
        link: node.objectLink,
        metadataId: node.metadata?.incidentNumber || '',
        drive: node?.drive?.edges
          ? {
              id: node?.drive?.edges[0]?.node?.id,
              name: node?.drive?.edges[0]?.node?.driveName
            }
          : { id: '', name: '' },
        entitiesCount: node.entity.count,
        entities: node?.entity?.edges.map(({ node }) => ({
          id: node.id,
          names: node.name
        })),
        attributesCount: node.attribute.count,
        attributes: node?.attribute?.edges.map(({ node }) => ({
          internalName: node.internalName,
          name: node.name
        })),
        alertsCount: node.alert?.count,
        alerts: node?.alert?.edges.map(({ node }) => ({
          id: node.id,
          name: node.name,
          severity: node.severity || ''
        })),
        projectName:
          node?.ticket?.edges[0]?.node?.ticketStoreProject?.edges[0]?.node?.projectName || '',
        ticketName: node?.ticket?.edges[0]?.node?.ticketName || '',
        ticketId: node?.ticket?.edges[0]?.node?.id || '',
        ticketLink: node?.ticket?.edges[0]?.node?.ticketLink || '',
        reporter: node?.ticket?.edges[0]?.node?.reporter?.name || '',
        assignee: node?.ticket?.edges[0]?.node?.assignee?.name || '',
        ticketLastModifiedTime: node?.ticket?.edges[0]?.node?.lastModifiedTime || '',
        buckets: node?.bucket?.edges?.map(({ node: item }) => ({
          name: item.displayName || item.name
        })),
        labels: node?.labels?.edges.map(({ node: label }) => ({
          id: label.id,
          name: label.name,
          labelSetId: label.labelSetId,
          labelSetName: label.labelSetName,
          mappedLabelId: label.mappedLabel.edges?.[0]?.node?.id || '',
          mappedLabelName: label.mappedLabel.edges?.[0]?.node?.name || '',
          mappedLabelSetId: label.mappedLabel.edges?.[0]?.node?.labelSetId || '',
          mappedLabelSetName: label.mappedLabel.edges?.[0]?.node?.labelSetName || ''
        }))
      })) || []

    return { list }
  } catch (e) {
    return { list: [] }
  }
}

export const queryDocumentCount = (
  params: IGetAttributesParams &
    IGetAttributesTableListParams & {
      filters?: FilterParams
      retainUntil?: string
      lastModifiedTime?: string
      retainUntilTime?: string
      deleteAfterTimestamp?: string
    }
): string => {
  const { datasourceId, page, filters: _filters, lastModifiedTime } = params

  const filters = JSON.parse(JSON.stringify(_filters))

  // Labels
  const labelFilter = filters?.documentLabelIds

  const dateFilterKey = FILTER_LAST_MODIFIED_TIME_KEY
  const dateFilterValue = lastModifiedTime

  const { startTimestamp, endTimestamp } = [
    LAST_MODIFIED_TIME.withinThreeMonths,
    LAST_MODIFIED_TIME.withinSixMonths,
    LAST_MODIFIED_TIME.withinOneYear
  ].includes(lastModifiedTime as LAST_MODIFIED_TIME)
    ? mapWithinStringToTimeStamp(lastModifiedTime)
    : mapStringToTimeStamp(lastModifiedTime)
  const dateFilter = dateFilterValue
    ? `dateFilter: {key: ${dateFilterKey}, start: ${startTimestamp}, end:${endTimestamp} }`
    : ''
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = `${dateFilter}`
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''
  if (datasourceId) {
    paramStr += `, datasourceIds:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (labelFilter) {
    paramStr += `, documentLabelIds: ${JSON.stringify(labelFilter)}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  if (paramStr) {
    paramStr = `(first: ${LIMIT_DEFAULT},${paramStr},after:"${cursor}", category:FILE)`
  }

  return gql`
  {
    objectsCount: objects${paramStr}{
      count
    }
  }`
}

export const mapQueryDocumentCount = (queryResponse: any): FileListResponseType => {
  try {
    return { total: queryResponse.objectsCount.count || 0 }
  } catch (e) {
    return { total: 0 }
  }
}

export const queryDocumentListByBlob = (
  params: IGetAttributesParams &
    IGetAttributesTableListParams & {
      filters?: FilterParams
      retainUntil?: string
      lastModifiedTime?: string
      retainUntilTime?: string
      deleteAfterTimestamp?: string
    }
): string => {
  const { blobId } = params
  return gql`
  {
      columns(id: "${blobId}") {
        edges {
          node {
            name
            blobCell(first: 50) {
              edges {
                node {
                  rowId
                  attributes
                }
              }
            }
          }
        }
      }
  }`
}

export const mapQueryDocumentListByBlob = (queryResponse: any): FileListResponseType => {
  try {
    const list: ClassificationObject[] =
      queryResponse?.columns?.edges[0]?.node?.blobCell?.edges?.map((item) => ({
        rowId: item?.node?.rowId,
        attributes: item?.node?.attributes
      })) || []
    return { list, total: queryResponse.columns.count || 0 }
  } catch (e) {
    return { list: [], total: 0 }
  }
}

// TODO: add type for graphql response
export const mapQueryTopClassification = (raw: any): ClassificationTopWidget[] => {
  return raw.attributeInstanceGroupedbyDocumentClass.map((docClass) => ({
    documentClass: docClass.documentClass,
    attributeInstanceCount: docClass.attributeInstanceCount || 0
  }))
}

export const queryClassificationsList = (
  params: ClassifiedObjectsParams & {
    lastModifiedTime?: string
    loadMipTags?: boolean
  }
): string => {
  const { datasourceId, filters, page, lastModifiedTime, loadMipTags } = params

  // remove labels filter from filters array
  const labelsInitFilter = params.filters?.filter?.find((filter) => filter.key === LABELS_KEY)
  let documentLabelIds: string[] = []
  if (params.filters?.filter && labelsInitFilter) {
    documentLabelIds = labelsInitFilter.values
    params.filters.filter = params.filters.filter.filter((filter) => filter.key !== LABELS_KEY)
  }
  const documentLabelIdsFilter = documentLabelIds.length
    ? `, documentLabelIds: ${JSON.stringify(documentLabelIds)}`
    : ''

  const { startTimestamp, endTimestamp } = mapStringToTimeStamp(lastModifiedTime)
  const dateFilter = lastModifiedTime
    ? `dateFilter: {key: ${FILTER_LAST_MODIFIED_TIME_KEY}, start: ${startTimestamp}, end:${endTimestamp} }`
    : ''
  const cursor = getAfterCursor(page, LIMIT_DEFAULT)
  let paramStr = `${dateFilter}`
  const filterString = filters && filters.filter ? parameterizeArrayofObjects(filters.filter) : ''
  const booleanFilterString =
    filters && filters.booleanFilter ? parameterizeArrayofObjects(filters.booleanFilter) : ''
  if (datasourceId) {
    paramStr += `, datasourceIds:"${datasourceId}"`
  }
  if (filterString) {
    paramStr += `, filter: ${filterString}`
  }
  if (booleanFilterString) {
    paramStr += `, booleanFilter: ${booleanFilterString} `
  }
  const queryAccessType = `${loadMipTags ? 'accessType,' : ''}`

  return gql`
    {
      objectsCount: objects(first: ${LIMIT_DEFAULT} ${documentLabelIdsFilter} ,${paramStr} ,after:"${cursor}", category:FILE){
        count
      }
      objects(first: ${LIMIT_DEFAULT} ${documentLabelIdsFilter} ,${paramStr},after:"${cursor}", category:FILE) {
        edges{
        node{
          id
          name,
          classification,
          subclass,
          ${queryAccessType}
          type,
          lastModifiedTime,
          owner,
          objectLink,
          retainUntilTimestamp
          metadata {
            ... on ServiceNowMetadata {
              incidentNumber
            }
          }
          alert(first:5, filter: [{key: STATUS, values: ["Active"]}]){
            count
            edges {
              node {
                id
                name
                severity
              }
            }
          }
          attribute {
            edges {
              node{
                internalName
                name
              }
            }
            count
          }
          entity {
            edges {
              node {
                id
                name
              }
            }
            count
          }
          datasource {
            edges {
              node {
                id
                name
                type
              }
            }
          }
          labels {
            edges {
              node {
                id
                name
                labelSetId
                labelSetName
                mappedLabel {
                  edges {
                    node {
                      id
                      name
                      labelSetId
                      labelSetName
                    }
                  }
                }
              }
            }
          }
        }
      }
      }
    }`
}
export const mapQueryClassificationsList = (queryResponse: any): FileListResponseType => {
  try {
    const list = queryResponse.objects.edges.map(({ node }) => ({
      id: node.id,
      name: node.name,
      classification: node.classification,
      ...(node.accessType ? { accessType: node.accessType } : {}),
      subclass: node.subclass,
      fileType: node.type,
      lastModifiedTime: node.lastModifiedTime,
      retainUntilTimestamp: node.retainUntilTimestamp,
      owner: node.owner,
      isDeleted: node?.mail?.edges?.some(({ node }) => node.isDeleted) || false,
      link: node.objectLink,
      metadataId: node.metadata?.incidentNumber || '',
      entitiesCount: node.entity.count,
      entities: node?.entity?.edges.map(({ node }) => ({
        id: node.id,
        names: node.name
      })),
      attributesCount: node.attribute.count,
      attributes: node?.attribute?.edges.map(({ node }) => ({
        internalName: node.internalName,
        name: node.name
      })),
      alertsCount: node.alert?.count,
      alerts: node?.alert?.edges.map(({ node }) => ({
        id: node.id,
        name: node.name,
        severity: node.severity || ''
      })),
      datasourceId: node?.datasource?.edges[0]?.node?.id || '',
      datasourceName: node?.datasource?.edges[0]?.node?.name || '',
      datasourceType: node?.datasource?.edges[0]?.node?.type || '',
      labels: node?.labels?.edges.map(({ node: label }) => ({
        id: label.id,
        name: label.name,
        labelSetId: label.labelSetId,
        labelSetName: label.labelSetName,
        mappedLabelId: label.mappedLabel.edges?.[0]?.node?.id || '',
        mappedLabelName: label.mappedLabel.edges?.[0]?.node?.name || '',
        mappedLabelSetId: label.mappedLabel.edges?.[0]?.node?.labelSetId || '',
        mappedLabelSetName: label.mappedLabel.edges?.[0]?.node?.labelSetName || ''
      }))
    }))

    return { list, total: queryResponse.objectsCount.count || 0 }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryClassificationsPrint = (): string => gql`
  {
    objectGroupByClassificationType {
      classification
      objectCount
      subclass
    }
  }
`

export const mapQueryClassificationsPrint = (raw: any): ClassificationsPrint[] => {
  try {
    const list = raw.objectGroupByClassificationType.map((item) => ({
      classification: item.classification,
      objectsCount: item.objectCount,
      subclasses: item.subclass
    }))

    return list
  } catch (e) {
    return []
  }
}

export const parseAnalyticsFile = (raw: any): AnalyticsItem[] => {
  try {
    const parsedData = raw.map((ds) => {
      return {
        id: ds.datasourceId || '',
        name: ds.datasourceName || '',
        type: mapDataSourceApiTypesToStandard(ds.datasourceType) || 'GENERIC',
        size: ds.totalSize || 0,
        sharedDriveSize: ds.totalSharedDriveSize || 0,
        personalDriveSize: ds.totalPersonalDriveSize || 0,
        duplicatesCount: ds.totalDuplicateFiles || 0,
        duplicatesSize: ds.totalDuplicateFileSize || 0,
        lastScan: ds.lastScanTimeStamp || 0,
        distrByFile: ds.distributionByFileType || [{ key: 'OTHERS', value: 1 }],
        distrBySize: ds.distributionBySize || [{ key: 'FROM_16_TO_128KB', value: 1 }],
        distrByUpdate: ds.distributionByUpdateTime || [{ key: 'FROM_0_TO_3MONTHS', value: 1 }]
      }
    })
    return parsedData
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryFilesGroupedBuLabelSet = (datasourceId?: string): string => {
  const dsFilter = datasourceId ? `datasourceIds:"${datasourceId}"` : ''
  const filters = dsFilter ? `(${dsFilter})` : ''
  return gql`
    {
      documentLabelSet${filters} {
        edges {
          node {
            id
            name
            labels {
              count
              edges {
                node {
                  id
                  name
                  type
                  objectCount
                  # piiObjects: objects(
                  #   booleanFilter: { key: IS_SENSITIVE, value: true }
                  #   category: FILE
                  #   ${dsFilter}
                  # ) {
                  #   count
                  # }
                }
              }
            }
          }
        }
      }
    }
  `
}
export const mapQueryFilesGroupedBuLabelSet = (raw: any): DocumentClassCards[] => {
  try {
    const matrix = raw.documentLabelSet?.edges?.map(({ node: set }) => {
      return set.labels.edges.map(({ node: label }) => ({
        labelSetId: set.id,
        labelSetName: set.name,
        accessType: set.name,
        documentsCount: label?.objectCount,
        // riskyDocumentsCount: label?.piiObjects.count,
        labelId: label.id,
        labelName: label.name,
        type: label.type
      }))
    })
    return matrix.flat()
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const queryRescanDocuments = ({ datasourceId, documentIds }: any): string => {
  return gql`
    mutation {
      rescanDocuments(
        actionInput: {
          clientMutationId: "1"
          datasourceId: "${datasourceId}"
          documentIds: ${JSON.stringify(documentIds)}
        }
      ) {
        clientMutationId
        status
      }
    }
  `
}
